<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="关键字" label-width="60px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称/编码/电话" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1 flex align-center">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<el-upload
				  class="upload-demo d-inline-block"
				  ref="upload"
				  action="#"
				  :on-change="importExcel"
				  :on-success="importSuccess"
				  :on-error="importError"
				  :before-upload="beforeImportUpload"
				  :limit="1"
				  :multiple="false"
				  :show-file-list="false"
				  accept=".xls, .xlsx"
				  :auto-upload="false"
				  >
				  <el-button slot="trigger" icon="el-icon-upload2" size="small" type="primary">导入</el-button>
				</el-upload>
				<el-button type="primary" icon="el-icon-download" size="mini" @click="downloadTemplate">下载模板</el-button>
				<div class="d-inline-block ml-1" style="width: 200px;">
					<el-input v-model="queryForm.keyword" placeholder="请输入名称/编码/电话" type="text" clearable style="width: 200px" />
				</div>
				<div class="d-inline-block ml-1" style="width: 200px;">
					<el-select v-model="queryForm.nianji" placeholder="请选择年级" size="medium" class="w-100" @change="getList">
						<el-option :value="item.id" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
					</el-select>
				</div>
				<div class="d-inline-block ml-1" style="width: 200px;">
<!-- 					<el-select v-model="queryForm.xueke" placeholder="请选择学科" size="medium" class="w-100" @change="getList">
					  <el-option label="全部" value=""></el-option>
					  <el-option label="语文" value="1"></el-option>
					  <el-option label="数学" value="2"></el-option>
					  <el-option label="英语" value="3"></el-option>
					  <el-option label="物理" value="4"></el-option>
					  <el-option label="化学" value="5"></el-option>
					  <el-option label="生物" value="6"></el-option>
					  <el-option label="政治" value="7"></el-option>
					  <el-option label="历史" value="8"></el-option>
					  <el-option label="地理" value="9"></el-option>
					  <el-option label="体育" value="10"></el-option>
					  <el-option label="音乐" value="11"></el-option>
					  <el-option label="美术" value="12"></el-option>
					</el-select> -->
					<el-select v-model="queryForm.xueke" placeholder="请选择学科" size="medium" class="w-100" @change="getList">
						<el-option :value="item.id" :label="item.name" v-for="(item,index) in xueke" :key="item.id"/>
					</el-select>
				</div>
				<div class="d-inline-block ml-1">
					<el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
					<el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</div>
				<div class="ml-auto">
					<el-tooltip class="item" effect="dark" content="刷新" placement="top">
					  <el-button size="mini" circle icon="el-icon-refresh" @click="getList" />
					</el-tooltip>
				</div>
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
<!-- 				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column> -->
				<el-table-column label="名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.username }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="编码" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.code }}
				  </template>
				</el-table-column>
				<el-table-column label="电话" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.mobile }}
				  </template>
				</el-table-column>
				<el-table-column label="年级" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.nianji_txt }}
				  </template>
				</el-table-column>
				<el-table-column label="学科" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.xueke_txt }}
				  </template>
				</el-table-column>
				<el-table-column label="性别" min-width="110" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.gender === 1" size="mini" type="primary">男</el-tag>
					<el-tag v-if="scope.row.gender === 2" size="mini" type="warning">女</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="年龄" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.age }}
				  </template>
				</el-table-column>
				<el-table-column label="从业时间" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.work_date }}
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="50" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">退休</el-tag>
					<el-tag v-if="scope.row.status === 3" size="mini" type="danger">调出</el-tag>
					<el-tag v-if="scope.row.status === 4" size="mini" type="danger">辞职</el-tag>
					<el-tag v-if="scope.row.status === 5" size="mini" type="danger">休假</el-tag>
					<el-tag v-if="scope.row.status === 6" size="mini" type="danger">不在考核范围</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="120">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="130" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="查看" placement="top">
					  <el-button type="primary" icon="el-icon-view" size="mini" circle @click="handleView(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="800px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
		    <el-form-item label="名称" prop="username" style="width: 450px;">
		      <el-input v-model="form.username" placeholder="请输入老师名称" type="text" clearable />
		    </el-form-item>
			<el-form-item label="编码" prop="code" style="width: 450px;">
			  <el-input v-model="form.code" placeholder="请输入老师编码" type="text" clearable />
			</el-form-item>
			<el-form-item label="密码" prop="password" style="width: 450px;">
			  <el-input v-model="form.password" placeholder="请输入登录密码" type="password" clearable />
			  <small class="text-danger font-s" v-if="form.id">为空表示不修改密码</small>
			</el-form-item>
		    <el-form-item label="电话" prop="mobile" style="width: 450px;">
		      <el-input v-model="form.mobile" placeholder="请输入老师电话" type="text" clearable />
		    </el-form-item>
			<el-form-item label="年级" prop="nianji">
			  <el-select v-model="form.nianji" placeholder="请选择年级">
				<el-option :value="0" label="无"/>
			  	<el-option :value="item.id" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
			  </el-select>
			</el-form-item>
			<el-form-item label="学科" prop="xueke">
			  <el-select v-model="form.xueke" placeholder="请选择学科">
			  	<el-option :value="item.id" :label="item.name" v-for="(item,index) in xueke" :key="item.id"/>
			  </el-select>
			</el-form-item>
			<el-form-item  label="性别">
			   <el-radio-group v-model="form.gender">
			   	<el-radio :label="1">男</el-radio>
			   	<el-radio :label="2">女</el-radio>
			   </el-radio-group>
			 </el-form-item>
			<el-form-item label="年龄" prop="age" style="width: 450px;">
			  <el-input v-model="form.age" placeholder="请输入老师年龄" type="number" clearable />
			</el-form-item>
			<el-form-item label="民族" prop="nation" style="width: 450px;">
			   <el-input v-model="form.nation" placeholder="请输入民族" type="text" size="small" clearable></el-input>
			</el-form-item>
			<el-form-item label="毕业院校" prop="by_school" style="width: 450px;">
			   <el-input v-model="form.by_school" placeholder="请输入毕业院校" type="text" size="small" clearable></el-input>
			</el-form-item>
			<el-form-item label="职称" prop="zc" style="width: 450px;">
			   <el-select v-model="form.zc" placeholder="请选择学历" style="width: 100%;">
			     <el-option :value="item.name" :label="item.name" v-for="(item,index) in zc_list" :key="item.id"/>
			   </el-select>
			</el-form-item>
			<el-form-item label="最高学历" prop="xl" style="width: 450px;">
			   <el-select v-model="form.xl" placeholder="请选择学历" style="width: 100%;">
			     <el-option :value="item.name" :label="item.name" v-for="(item,index) in xl_list" :key="item.id"/>
			   </el-select>
			</el-form-item>
			<el-form-item label="从业时间" prop="work_date" style="width: 450px;">
			  <el-date-picker
				v-model="form.work_date"
				type="date"
				value-format="yyyy-MM-dd"
				class="w-100"
				placeholder="请选择从业时间">
			  </el-date-picker>
			</el-form-item>
			<el-form-item label="出生日期" prop="birthday" style="width: 450px;">
			  <el-date-picker
				v-model="form.birthday"
				type="date"
				value-format="yyyy-MM-dd"
				class="w-100"
				placeholder="请选择出生日期">
			  </el-date-picker>
			</el-form-item>
		   <el-form-item  label="状态">
		      <el-radio-group v-model="form.status">
		      	<el-radio :label="1">正常</el-radio>
		      	<el-radio :label="2">退休</el-radio>
		      	<el-radio :label="3">调出</el-radio>
		      	<el-radio :label="4">辞职</el-radio>
		      	<el-radio :label="5">休假</el-radio>
		      	<el-radio :label="6">不在考核范围</el-radio>
		      </el-radio-group>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'teacher',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					username:'',
					password:'',
					mobile:'',
					code:'',
					gender:1,
					age:'',
					status:1,
					xueke:'',
					nianji:'',
					work_date:'',
					birthday:'',
					nation:'',
					by_school:'',
					zc:'',
					xl:'',
				},
				rules:{
					username: [
					  { required: true, message: '请输入老师名称', trigger: 'blur' }
					],
					// work_date: [
					//   { required: true, message: '请选择从业时间', trigger: 'blur' }
					// ],
					code: [
					  { required: true, message: '请输入老师编码', trigger: ['blur', 'change'] }
					],
					// nation: [
					//   { required: true, message: '请输入民族', trigger: 'blur' }
					// ],
					// by_school: [
					//   { required: true, message: '请输入毕业院校', trigger: 'blur' }
					// ],
					// zc: [
					//   { required: true, message: '请输入职称', trigger: 'blur' }
					// ],
					// xl: [
					//   { required: true, message: '请输入最高学历', trigger: 'blur' }
					// ],
					// birthday: [
					//   { required: true, message: '请选择出生日期', trigger: ['blur', 'change'] }
					// ],
				},
				nianji:[],
				xueke:[],
				xl_list:[
					{id:1,name:'专科'},
					{id:2,name:'本科'},
					{id:3,name:'研究生'},
					{id:4,name:'博士'},
				],
				zc_list:[
					{id:1,name:'正高级教师'},
					{id:2,name:'高级教师'},
					{id:3,name:'一级教师'},
					{id:4,name:'二级教师'},
					{id:5,name:'三级教师'},
				],
			}
		},
		mounted() {
			this.getNianji()
			this.getXueke()
		},
		methods:{
			getNianji(){
				this.axios.get('/manage/nianji/all').then(res=>{
					if(res.status){
						this.nianji = res.data
					}
				})
			},
			getXueke(){
				this.axios.get('/manage/xueke/all').then(res=>{
					if(res.status){
						this.xueke = res.data
					}
				})
			},
			reset() {
			  this.form = {
			    username:'',
				password:'',
			    mobile:'',
			    code:'',
			    gender:1,
			    age:'',
			    status:1,
			    xueke:'',
			    nianji:'',
				work_date:'',
				birthday:'',
				nation:'',
				by_school:'',
				zc:'',
				xl:'',
			  }
			  this.resetForm('form')
			},
			downloadTemplate(){
				this.axios.get('/manage/teacher/downloadTemplate',{
					responseType: 'arraybuffer',
				}).then(res=>{
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '教师导入模板.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
			importExcel(e){
				//获取用户选择的文件
				const file = e.raw
				let formdata = new FormData()
				formdata.append('file', file)
				this.axios.post('/manage/teacher/importExport',formdata).then(res=>{
					if(res.status){
						this.$message.success('导入成功')
						this.getList()
					}else{
						this.$message.success('导入失败')
					}
					this.$refs.upload.clearFiles()
				})
			},
			beforeImportUpload(file){
				  let fileArr = file.name.split('.')
				  let suffix = fileArr[fileArr.length - 1]
				  //只能导入.xls和.xlsx文件
				  if (!/(xls|xlsx)/i.test(suffix)) {
					this.$message('文件格式不正确')
					return false
				  }
				  return true
			},
			handleView(row){
				this.$router.push({
				  path:"/teacher_detail",
				  query: {
					id: row.id,
				  }
				})
			},
		}
	}
</script>

<style>
	.upload-demo .el-button--small{
		padding:7px 15px;
		margin: 0 10px;
	}
	.el-input--medium .el-input__inner{
		height: 32px;
		line-height: 32px;
	}
</style>
